<template>
    <v-dialog
        class="page-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-form
            enctype="multipart/form-data"
            ref="pageSelectorForm"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title class="widget__heading">
                    Page Selection
                </v-card-title>

                <v-card-text>
                    <template v-if="pagesList">
                        <v-select
                            :items="pagesList"
                            item-text="postTitle"
                            item-value="postID"
                            v-model="selectedPage"
                            @change="setPage()"
                            clearable
                            flat
                            outline
                            return-object
                        ></v-select>

                        <v-select
                            v-if="showSubPages"
                            :items="subPagesList"
                            item-text="postTitle"
                            item-value="postID"
                            v-model="selectedSubPage"
                            @change="setPage()"
                            clearable
                            flat
                            outline
                            return-object
                        ></v-select>

                        <v-select
                            v-if="showSubSubPages"
                            :items="subSubPagesList"
                            item-text="postTitle"
                            item-value="postID"
                            v-model="selectedSubSubPage"
                            @change="setPage()"
                            clearable
                            flat
                            outline
                            return-object
                        ></v-select>

                        <v-select
                            v-if="showSubSubSubPages"
                            :items="subSubSubPagesList"
                            item-text="postTitle"
                            item-value="postID"
                            v-model="selectedSubSubSubPage"
                            @change="setPage()"
                            clearable
                            flat
                            outline
                            return-object
                        ></v-select>

                        <v-select
                            v-if="showSubSubSubSubPages"
                            :items="subSubSubSubPagesList"
                            item-text="postTitle"
                            item-value="postID"
                            v-model="selectedSubSubSubSubPage"
                            @change="setPage()"
                            clearable
                            flat
                            outline
                            return-object
                        ></v-select>
                    </template>

                    <template v-else>
                        <p>Pages are not available</p>
                    </template>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="selectedPage"
                        color="accent"
                        outline
                        flat
                        @click="sendPage();"
                    >
                        Set Page
                    </v-btn>
                    <v-btn
                        outline
                        flat
                        @click="nullData();"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import axios from "axios"
export default {
    name: "PageSelector",

    data: () => ({
        dialog: false,
        valid: false,
        api: `${process.env.VUE_APP_BASEURL}/pages`,
        pagesList: null,
        selectedPage: null,
        selectedSubPage: null,
        selectedSubSubPage: null,
        selectedSubSubSubPage: null,
        selectedSubSubSubSubPage: null,
        showSubPages: false,
        showSubSubPages: false,
        showSubSubSubPages: false,
        showSubSubSubSubPages: false,
        subPagesList: null,
        pageToSend: null
    }),

    created() {
        this.fetchPages()
    },

    methods: {
        fetchPages() {
            this.$store.commit("startLoading");
            this.errorMessage = "";

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(this.api, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        console.log("Fetch Pages: ", response);
                        this.pagesList = response.data.body;
                        this.$store.commit("completeLoading");
                    })
                    .catch(e => {
                        console.error("Problem retrieving pages", e);
                        this.errorMessage = "Problem retrieving pages";
                        this.$store.commit("completeLoading");
                    });
            })
        },
        setPage() {
            if (this.selectedPage) {
                this.pageToSend = 1

                if (this.selectedPage.children.length) {
                    this.showSubPages = true
                    this.subPagesList = this.selectedPage.children
                }
                if (this.selectedSubPage) {
                    this.pageToSend = 2

                    if (this.selectedSubPage.children.length) {
                        this.showSubSubPages = true
                        this.subSubPagesList = this.selectedSubPage.children
                    }
                    if (this.selectedSubSubPage) {
                        this.pageToSend = 3

                        if (this.selectedSubSubPage.children.length) {
                            this.showSubSubSubPages = true
                            this.subSubSubPagesList = this.selectedSubSubPage.children
                        }
                        if (this.selectedSubSubSubPage) {
                            this.pageToSend = 4

                            if (this.selectedSubSubSubPage.children.length) {
                                this.showSubSubSubPages = true
                                this.subSubSubSubPagesList = this.selectedSubSubSubPage.children
                            }
                            if (this.selectedSubSubSubSubPage) {
                                this.pageToSend = 5

                                if (this.selectedSubSubSubSubPage.children.length) {
                                    this.showSubSubSubSubSubages = true
                                    this.subSubSubSubSubPagesList = this.selectedSubSubSubSubPage.children
                                }
                                if (this.selectedSubSubSubSubSubPage) {
                                    this.pageToSend = 5

                                    if (this.selectedSubSubSubSubSubPage.children.length) {
                                        this.showSubSubSubSubSubSubPages = true
                                        this.subSubSubSubSubSubPagesList = this.selectedSubSubSubSubPage.children
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        sendPage() {
            switch (this.$data.pageToSend) {
                case 5:
                    console.log("case", 5)
                    this.$emit("pageSelected", this.selectedSubSubSubSubPage.postID)
                    this.$emit("pageUrl", this.selectedSubSubSubSubPage.fullPath)
                    this.nullData()
                    break;
                case 4:
                    console.log("case", 4)
                    this.$emit("pageSelected", this.selectedSubSubSubPage.postID)
                    this.$emit("pageUrl", this.selectedSubSubSubPage.fullPath)
                    this.nullData()
                    break;
                case 3:
                    console.log("case", 3)
                    this.$emit("pageSelected", this.selectedSubSubPage.postID)
                    this.$emit("pageUrl", this.selectedSubSubPage.fullPath)
                    this.nullData()
                    break;
                case 2:
                    console.log("case", 2)
                    this.$emit("pageSelected", this.selectedSubPage.postID)
                    this.$emit("pageUrl", this.selectedSubPage.fullPath)
                    this.nullData()
                    break;
                case 1:
                    console.log("case", 1)
                    this.$emit("pageSelected", this.selectedPage.postID)
                    this.$emit("pageUrl", this.selectedPage.fullPath)
                    this.nullData()
                    break;
                default:
                    this.nullData()
            }
        },
        nullData() {
            this.dialog = false
            this.selectedPage = null
            this.selectedSubPage = null
            this.selectedSubSubPage = null
            this.selectedSubSubSubPage = null
            this.selectedSubSubSubSubPage = null
            this.showSubPages = false
            this.showSubSubPages = false
            this.showSubSubSubPages = false
            this.showSubSubSubSubPages = false
        }
    }
}
</script>

<style scoped lang='scss'>
</style>
